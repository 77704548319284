// @ts-strict-ignore
import { config } from '../config';
import { BlackFridayPlans, RapidSearchPlans, ShopifyPlanName } from '../enums';
import { Settings } from '../types.d';

const {
  adminDomain,
  crispWebId,
  esDomain,
  firebaseApiKey,
  ga4MeasurementId,
  shopifyAdminDomain,
  shopifyAppUrl,
  shopifyExtensionId,
  shoprenterAdminDomain,
  shoprenterAppUrl,
  staticDomain,
  isDev,
  isProd,
} = config;

const subscriptionLocalStorageKey = 'RS_subscription_start';
const subscriptionPlanLocalStorageKey = 'RS_subscription_plan';
const subscriptionTypeLocalStorageKey = 'RS_subscription_type';
const subscriptionInnerIdLocalStorageKey = 'RS_subscription_inner_id';
const shopDomainLocalStorageKey = 'RS_admin_shop_domain';
const SRstoreDomain = document.referrer ? document.referrer.match(/^https?:\/\/[^/?#]+/i)[0] : '';
const SRBillingDataLink = `${SRstoreDomain}/admin/spa/#/billingAddress`;
const primaryColor = '#3F4EAE';
const mpAdminTitle = 'Hazaitól a Házhoz Admin';
const routes = {
  login: '/bejelentkezes',
  signup: '/regisztracio',
  resetPassword: '/elfelejtett-jelszo',
  install: '/telepites',
};
const authRoutes = [routes.login, routes.signup, routes.resetPassword];

const trialPlans = [RapidSearchPlans.TRIAL, RapidSearchPlans.SHOPIFY_TRIAL];

const paidPlans: RapidSearchPlans[] = [
  RapidSearchPlans.STARTER,
  RapidSearchPlans.STARTER2,
  RapidSearchPlans.STARTER3,

  RapidSearchPlans.ADVANCED0,
  RapidSearchPlans.ADVANCED,
  RapidSearchPlans.ADVANCED2,
  RapidSearchPlans.ADVANCED3,

  RapidSearchPlans.PREMIUM,
  RapidSearchPlans.PREMIUM2,
  RapidSearchPlans.PREMIUM3,

  RapidSearchPlans.ENTERPRISE2,
  RapidSearchPlans.ENTERPRISE,
  RapidSearchPlans.ENTERPRISE3,
  RapidSearchPlans.ENTERPRISE_CHILD,

  RapidSearchPlans.BASIC_1,
  RapidSearchPlans.BASIC_2,
  RapidSearchPlans.BASIC_3,
  RapidSearchPlans.PRO_1,
  RapidSearchPlans.PRO_2,
  RapidSearchPlans.PRO_3,
  RapidSearchPlans.PRO_4,
  RapidSearchPlans.SHOPIFY_UNLIMITED,
  RapidSearchPlans.BF_BASIC_1,
  RapidSearchPlans.BF_BASIC_2,
  RapidSearchPlans.BF_BASIC_3,
  RapidSearchPlans.BF_PRO_1,
  RapidSearchPlans.BF_PRO_2,
  RapidSearchPlans.BF_PRO_3,
  RapidSearchPlans.BF_PRO_4,
  RapidSearchPlans.BF_SHOPIFY_UNLIMITED,
  RapidSearchPlans.SHOPIFY_CUSTOM,
];

const shoprenterStarterPlans = [RapidSearchPlans.STARTER, RapidSearchPlans.STARTER2, RapidSearchPlans.STARTER3];

const blackFridayShoprenterPlans = Object.values(BlackFridayPlans);
const defaultSidebarWidth = 240;
const instantSearchPreviewFrameId = 'rps_instant_search_preview';
const shopifyTestPlans = ['partner_test', 'affiliate', 'staff'];

const billingCycleToString = {
  1: 'monthly',
  3: 'quarterly',
  6: 'semiannual',
  12: 'annually',
};

const defaultColors: Settings['colors'] = {
  price: '#E52548',
  price_hover: '#FFFFFF',
  resource_hover: '',
  original_price: '#8F8F8F',
  original_price_hover: '#E3E3E3',
  search_results_price: '#E52548',
  search_results_price_hover: '#E52548',
  search_results_original_price: '#8F8F8F',
  search_results_original_price_hover: '#8F8F8F',
  search_results_title: '#555555',
  search_results_title_hover: '#555555',
  sticky_widget_background: '#FFFFFF',
  sticky_widget_icon: '#A9A9A9',
  new_badge_background: '#1A9CFB',
  new_badge_font_color: '#FFFFFF',
  discount_badge_background: '#E52548',
  discount_badge_font_color: '#FFFFFF',
  in_stock_badge: '#008000',
  low_stock_badge: '#FFA500',
  out_of_stock_badge: '#F00540',
  low_stock_badge_background: '#FFFFFF',
  out_of_stock_badge_background: '#FFFFFF',
  in_stock_badge_background: '#FFFFFF',
  pre_order_badge: '#0090D4',
  pre_order_badge_background: '#FFFFFF',
};

const backendBasePaths = {
  shopifyApi: `${shopifyAdminDomain}/api/shopify`,
  serverAdmin: `${adminDomain}/api`,
};

const icpPlans = [ShopifyPlanName.SHOPIFY_PLUS, ShopifyPlanName.PROFESSIONAL, ShopifyPlanName.UNLIMITED];

const icpCountries = ['US', 'GB', 'CA', 'DK'];

export const isEmbeddedAdmin = window.location !== window.parent?.location;

const isLiveEmbeddedAdmin = !isDev && isEmbeddedAdmin;

const daysInAMonth = 30;
const publicCdnBaseUrl = 'https://rapid-search-images.b-cdn.net/admin';

export {
  adminDomain,
  authRoutes,
  backendBasePaths,
  billingCycleToString,
  blackFridayShoprenterPlans,
  crispWebId,
  daysInAMonth,
  defaultColors,
  defaultSidebarWidth,
  esDomain,
  firebaseApiKey,
  ga4MeasurementId,
  icpCountries,
  icpPlans,
  instantSearchPreviewFrameId,
  isDev,
  isLiveEmbeddedAdmin,
  isProd,
  mpAdminTitle,
  paidPlans,
  primaryColor,
  publicCdnBaseUrl,
  routes,
  shopDomainLocalStorageKey,
  shopifyAdminDomain,
  shopifyAppUrl,
  shopifyExtensionId,
  shopifyTestPlans,
  shoprenterAdminDomain,
  shoprenterAppUrl,
  shoprenterStarterPlans,
  SRBillingDataLink,
  SRstoreDomain,
  staticDomain,
  subscriptionInnerIdLocalStorageKey,
  subscriptionLocalStorageKey,
  subscriptionPlanLocalStorageKey,
  subscriptionTypeLocalStorageKey,
  trialPlans,
};
