// @ts-strict-ignore
import posthog from 'posthog-js';
import { appState } from 'store';

import { isDev, isLiveEmbeddedAdmin } from '../constants';
import { isIcpStore } from '../icpDetection';
import { getParentQueryString, logBold } from '../statelessFunctions';
import { posthogHost, posthogKey } from './constants';
import { PosthogEventProperties, PosthogEvents, SyncTypes } from './types';

class PosthogHandler {
  static isVerboseMode = false;
  static isPostHogLoaded = false;

  private static lastPageViewUrl = '';

  public static initPosthog(): void {
    const { posthog: posthogParam } = getParentQueryString();
    const loadPostHog = isLiveEmbeddedAdmin || !!posthogParam;

    if (loadPostHog) {
      PosthogHandler.identifyUser();
    }

    PosthogHandler.isVerboseMode = isDev || posthogParam === '1';
    PosthogHandler.isPostHogLoaded = true;
  }

  private static identifyUser(): void {
    const { storeId, settings } = appState;

    posthog.init(posthogKey, {
      capture_pageview: false,
      api_host: posthogHost,
      loaded: (posthogInstance) => {
        posthogInstance.identify(storeId, { email: storeId });
      },
    });

    posthog.setPersonProperties({
      rs_plan: settings.current_plan,
      is_icp: isIcpStore(),
      engine: settings.webshop_engine,
      country_code: settings.country_code,
    });
  }

  public static triggerSyncProgressBarFinishedEvent(): void {
    PosthogHandler.sendPosthogEvent(PosthogEvents.SyncProgressbarFinished, {
      type: SyncTypes.FirstSync,
    });
  }

  public static triggerSubscriptionEvent(): void {
    const { subscription_info } = appState.settings;
    const { price, plan } = subscription_info;

    PosthogHandler.sendPosthogEvent(PosthogEvents.SubscriptionUpdate, {
      plan_name: plan,
      price,
      billing_cycle: subscription_info.billing_cycle,
    });
  }

  public static triggerFreePlanEvent(): void {
    const { current_plan } = appState.settings;

    PosthogHandler.sendPosthogEvent(PosthogEvents.SubscriptionUpdate, {
      plan_name: current_plan,
      price: 0,
    });
  }

  public static triggerWebVital(event: PosthogEvents.LCP | PosthogEvents.FCP, properties: PosthogEventProperties): void {
    PosthogHandler.sendPosthogEvent(event, properties);
  }

  public static sendPosthogPageViewEvent(): void {
    if (PosthogHandler.lastPageViewUrl === location.href) {
      return;
    }

    const eventIsSent = PosthogHandler.sendPosthogEvent(PosthogEvents.PageView);

    if (eventIsSent) {
      PosthogHandler.lastPageViewUrl = location.href;
    }
  }

  private static sendPosthogEvent(event: PosthogEvents, properties?: PosthogEventProperties): boolean {
    if (!PosthogHandler.isPostHogLoaded) {
      return false;
    }

    if (PosthogHandler.isVerboseMode) {
      logBold('Sending Posthog Event: ', event);
      logBold('Is Posthog loaded: ', PosthogHandler.isPostHogLoaded);
    }

    posthog.capture(event, properties);
    return true;
  }
}

export default PosthogHandler;
