export enum ShoprenterPlanFamily {
  STARTER = 'starter',
  ADVANCED = 'advanced',
  PREMIUM = 'premium',
  ENTERPRISE = 'enterprise',
}

export enum RapidSearchPlans {
  TRIAL = 'trial',

  CANCELLED = 'cancelled',
  INSTALLED = 'installed',
  MARKETPLACE = 'marketplace',

  STARTER = 'starter',
  STARTER2 = 'starter2',
  STARTER3 = 'starter3',

  ADVANCED0 = 'advanced0',
  ADVANCED = 'advanced',
  ADVANCED2 = 'advanced2',
  ADVANCED3 = 'advanced3',

  PREMIUM = 'premium',
  PREMIUM2 = 'premium2',
  PREMIUM3 = 'premium3',

  ENTERPRISE = 'enterprise',
  ENTERPRISE2 = 'enterprise2',
  ENTERPRISE3 = 'enterprise3',
  ENTERPRISE_CHILD = 'enterprise-child',

  SHOPIFY_TRIAL = 'shopify_trial',
  SHOPIFY_FREE_1 = 'shopify_free_1',

  BASIC_1 = 'shopify_basic_1',
  BASIC_2 = 'shopify_basic_2',
  BASIC_3 = 'shopify_basic_3',
  PRO_1 = 'shopify_pro_1',
  PRO_2 = 'shopify_pro_2',
  PRO_3 = 'shopify_pro_3',
  PRO_4 = 'shopify_pro_4',
  SHOPIFY_UNLIMITED = 'shopify_unlimited',

  BF_BASIC_1 = 'bf_shopify_basic_1',
  BF_BASIC_2 = 'bf_shopify_basic_2',
  BF_BASIC_3 = 'bf_shopify_basic_3',
  BF_PRO_1 = 'bf_shopify_pro_1',
  BF_PRO_2 = 'bf_shopify_pro_2',
  BF_PRO_3 = 'bf_shopify_pro_3',
  BF_PRO_4 = 'bf_shopify_pro_4',
  BF_SHOPIFY_UNLIMITED = 'bf_shopify_unlimited',

  SHOPIFY_CUSTOM = 'shopify_custom',
}

export enum ShoprenterPriceGroup {
  BEFORE_2022_11_26 = 'before_2022_11_26',
  AFTER_2022_11_26 = 'after_2022_11_26',
  BF_2021 = 'bf_2021',
  SPECIAL = 'special',
}

export enum NotificationTypes {
  open = 'open',
  info = 'info',
  success = 'success',
  error = 'error',
}

export enum ZeroSearchMode {
  popularity = 'popularity',
  custom = 'custom',
}

export enum MessageType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  LOADING = 'loading',
}

export enum SaveSettingsText {
  SUCCESS = 'settings_saved',
  ERROR = 'save_setting_error',
  OneMinute = 'settings_saved_60sec',
}

export enum ShopifyPricingEndpointType {
  CREATE = 'create',
  FREE = 'free',
  CUSTOM = 'custom',
  VALIDATION = 'validate',
}

export enum FocType {
  GENERAL = 'general',
}

export enum StatisticsTypes {
  ANALYTICS = 'analytics',
  CONVERSION = 'conversions',
  TOP_KEYWORDS = 'topkeywords',
  TOP_PRODUCTS = 'topproducts',
  ALL_KEYWORDS = 'allkeywords',
}

export enum SubscriptionState {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  FROZEN = 'FROZEN',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export enum ShopifyPlanName {
  TRIAL = 'trial',
  BASIC = 'basic',
  PROFESSIONAL = 'professional',
  UNLIMITED = 'unlimited',
  SHOPIFY_PLUS = 'shopify_plus',
}

export enum SyncPaths {
  TAGS = 'tags',
  COLOR_FAMILY = 'colorFamily',
  HIDE_PRODUCTS_BY_TAGS = 'updateResourceVisibility/products',
}

export enum HideResourcesType {
  PRODUCT = 'Product',
  COLLECTION = 'Collection',
  TAGS = 'Tags',
}

export enum AdminEvents {
  APP_INSTALL = 'appInstall',
  FACEBOOK_INSTALL = 'facebookInstall',
  GOOGLE_ADS_INSTALL = 'gAdsInstall',
  FIRST_SYNC_STARTED = 'firstSyncStarted',
}

export enum AdminRoles {
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin',
}

export enum RestrictedFeatures {
  SUPER_ADMIN = 'superAdmin',
  TEST_STORE = 'test',
}

export enum CustomEvents {
  INSTALL = 'Install',
  SUBSCRIPTION_UPDATE = 'Subscription-update',
  SUBSCRIPTION_NEW = 'Subscription-new',
  GADS_CONVERSION = 'conversion',
}

export enum ChatCampaigns {
  DISABLE_APP = 'user:disabled_app',
  DEMO_CALL = 'user:demo_call',
  FIVE_STARS_REVIEW = 'user:five_stars',
}

export enum PaymentType {
  CARD = 'card',
  INVOICE = 'invoice',
}

export enum WebshopEngine {
  SHOPIFY = 'shopify',
  SHOPRENTER = 'shoprenter',
  WOOCOMMERCE = 'woocommerce',
  MAGENTO2 = 'magento2',
  CUSTOM = 'custom',
  LOGISHOP = 'logishop',
  UNAS = 'unas',
  AUCHAN = 'auchan',
}

export enum ShopifyPriceGroup {
  NORMAL,
  BF_2023,
}

export enum FilterSortingOption {
  ProductCountDescending = 'count_desc',
  AlphabeticalAscending = 'alphabet_asc',
  AlphabeticalDescending = 'alphabet_desc',
  NumericAscending = 'numeric_asc',
  NumericDescending = 'numeric_desc',
  SizeAscending = 'size_asc',
  SizeDescending = 'size_desc',
}

export enum DefaultResultpageFilters {
  Category = 'category',
  Price = 'price',
  Brand = 'brand',
  Tags = 'tags',
  Availability = 'availability',
  Attributes = 'attributes',
  ProductType = 'product_type',
}

export enum SubscriptionValidationResponseStatus {
  ACTIVE = 'active',
  ERROR = 'error',
  PENDING = 'pending',
  NOT_ACTIVE = 'not_active',
  DECLINED = 'declined',
}

export enum SyncReferrer {
  SAVE_BUTTON = 'save_button',
  COMPLETE_INSTALL = 'complete_install',
  MANUAL_SYNC = 'manual_sync',
  TRIAL_EXTENSION = 'trial_extension',
  SYNONYM_UPDATE = 'synonym_update',
  SUBSCRIPTION = 'subscription',
}

export enum BlackFridayPlans {
  BF_STARTER2 = 'bf_starter2',
  BF_ADVANCED2 = 'bf_advanced2',
  BF_PREMIUM2 = 'bf_premium2',
  BF_ENTERPRISE2 = 'bf_enterprise2',
}

export enum SettingVisibility {
  ALL = 'all',
  SHOPIFY = 'shopify',
  SHOPRENTER = 'shoprenter',
  MARKETPLACE = 'marketplace',
  MAGENTO2_SUPER_ADMIN = 'magento2_super_admin',
}

export enum ResultpageSearchBarVisibility {
  ShowAlways = 'show_always',
  Hidden = 'hidden',
  DesktopOnly = 'desktop_only',
  MobileOnly = 'mobile_only',
}

export enum SortingOptions {
  relevance = '',
  popularity = 'popularity',
  price_asc = 'price_asc',
  discount_percent = 'discount_percent',
  latest = 'latest',
}

export enum SearchAnalyticsTabs {
  AllSearches = 'all_searches',
  NoResultsSearch = 'no_result_searches',
  TrendingProducts = 'trending_products',
  TrendingSearches = 'trending_searches',
  Orders = 'orders',
}

export enum BadgeVisibility {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}
