// @ts-strict-ignore
import { ResultPageFilterTypes } from '@rapidsearch/shared/lib/enums';
import { PaymentType, RapidSearchPlans, ShopifyPlanName, SubscriptionState } from 'enums';
import queryString from 'query-string';

import { ShopifyPartners } from '../types.d';
import LocalStorage from './LocalStorage/LocalStorage';
import {
  adminDomain,
  backendBasePaths,
  icpPlans,
  isDev,
  paidPlans,
  publicCdnBaseUrl,
  shopDomainLocalStorageKey,
} from './constants';
import { SetStatisticsDate } from './statistics/types';

// biome-ignore lint/suspicious/noExplicitAny:
function getParentQueryString(): any {
  return queryString.parse(window.location.search);
}

function getStoreId(): string {
  const { shop: parsedShop, shopname } = getParentQueryString();
  const SRparsedShop = shopname ? `${shopname}.shoprenter.hu` : null;
  const shopDomainInLocalStorage = new LocalStorage(shopDomainLocalStorageKey).getItem();

  return parsedShop || SRparsedShop || shopDomainInLocalStorage;
}

// biome-ignore lint/suspicious/noExplicitAny:
const saveSettingRequest = async (storeId: string, settings: Record<string, any>): Promise<boolean> => {
  try {
    const url = `${adminDomain}/api/settings/${storeId}`;
    const requestInit: RequestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(settings),
    };
    const response = await fetch(url, requestInit);

    return response.ok;
  } catch {
    return false;
  }
};

const getIsMarketplaceAdmin = (): boolean => {
  const domain = isDev ? 'localhost:3003' : 'admin.hazaitolahazhoz.hu';

  return window.location.host === domain;
};

function getPreferredLanguage(): string {
  const browserLanguageCode = navigator?.language?.split('-')[0];
  return browserLanguageCode || 'en';
}

function openUrlInTopWindow(url: string) {
  window.open(url, '_top');
}

function checkIfPaidPlan(planId: RapidSearchPlans): boolean {
  return paidPlans.includes(planId);
}

// biome-ignore lint/suspicious/noExplicitAny:
const sendJsonRequest = (url: string, body: any): Promise<Response> => {
  const requestInit: RequestInit = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  return fetch(url, requestInit);
};

function isIcpPlanName(planName: ShopifyPlanName) {
  return icpPlans.includes(planName);
}

function isActiveSubscription(subscriptionState: SubscriptionState) {
  return subscriptionState === SubscriptionState.ACTIVE;
}

function getIsCardPayment(paymentType: PaymentType) {
  return paymentType === PaymentType.CARD;
}

async function getShopifyPartnersData(): Promise<ShopifyPartners> {
  try {
    const url = `${backendBasePaths.shopifyApi}/getPartnersData`;
    const response = await fetch(url);

    if (response.ok) {
      return response.json();
    }

    return null;
  } catch {
    return null;
  }
}

function getCustomDate(startString: string, endString: string): { endDate: string; startDate: string } {
  const Moment = require('moment');
  const MomentRange = require('moment-range');
  const extendMoment = MomentRange.extendMoment(Moment);
  const endDate = extendMoment(endString).format('ll');
  const startDate = extendMoment(startString).format('ll');

  return {
    endDate,
    startDate,
  };
}

function getStatisticsDateInfo({ id, startDate, endDate }: SetStatisticsDate) {
  return {
    defaultValue: id,
    endDate,
    startDate,
  };
}

function percentage(partialValue: number, totalValue: number): number {
  return (100 * partialValue) / totalValue;
}

function calculateOverUsePercentage(count: number, limit: number): number {
  return limit === 0 ? 0 : Math.trunc(percentage(count, limit));
}

function getFileCdnUrl(file: string): string {
  return `${publicCdnBaseUrl}/${file}`;
}

export function isAttributeFilter(filterType: ResultPageFilterTypes): boolean {
  return filterType === ResultPageFilterTypes.ATTRIBUTE;
}

export function isTagFilter(filterType: ResultPageFilterTypes): boolean {
  return filterType === ResultPageFilterTypes.TAG;
}

export function isDefaultFilter(filterType: ResultPageFilterTypes): boolean {
  return filterType === ResultPageFilterTypes.DEFAULT;
}

export function getIsShopifyCustomPlan(planId: RapidSearchPlans): boolean {
  return planId === RapidSearchPlans.SHOPIFY_CUSTOM;
}

export function getIsShopifyUnlimitedPlan(planId: RapidSearchPlans): boolean {
  return planId === RapidSearchPlans.SHOPIFY_UNLIMITED;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function logBold(message: string, ...params: any[]): void {
  console.log(`%c${message}`, 'font-weight: bold;', ...params);
}

export {
  calculateOverUsePercentage,
  checkIfPaidPlan,
  getCustomDate,
  getFileCdnUrl,
  getIsCardPayment,
  getIsMarketplaceAdmin,
  getParentQueryString,
  getPreferredLanguage,
  getShopifyPartnersData,
  getStatisticsDateInfo,
  getStoreId,
  isActiveSubscription,
  isIcpPlanName,
  openUrlInTopWindow,
  percentage,
  saveSettingRequest,
  sendJsonRequest,
};
