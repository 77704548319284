// @ts-strict-ignore
import { RapidSearchPlans, ShoprenterPlanFamily } from 'enums';
import moment from 'moment';
import { CampaignInfo } from 'types.d';

export const defaultCampaignValues: CampaignInfo = {
  title: '',
  subtitle: '',
  content: '',
  isActive: false,
  end: moment(),
  endAsTimeStamp: 0,
  bannerName: null,
  bannerImageName: '',
  pricingButtonTitle: '',
};

export const shopifyBlackFridayStartDate = moment('2023-10-23 20:00');

export function getShopifyBlackFridayCampaign(campaignEndDate: Date): CampaignInfo {
  const campaignEndMoment = moment(campaignEndDate);

  return {
    title: 'Black Friday 2023 Exclusive Offer',
    subtitle: 'Subscribe Now & Save 20% on All Packages',
    content:
      "Maximize your revenue with the power of AI! Subscribe to any of our plans today with an exclusive 20% discount. Don't miss out!",
    isActive: true,
    end: campaignEndMoment,
    endAsTimeStamp: campaignEndMoment.valueOf(),
    bannerName: 'black_friday',
    bannerImageName: 'black_friday_banner.webp',
    pricingButtonTitle: 'Subscribe Now',
  };
}

const TwentyPercentDiscountSubtitle = '<p>Fizess elő 1 évre bármely<br />csomagunkra 20% kedvezménnyel</p>';

const shoprenterExpoCampaignEndDate = moment('2024-03-28').endOf('day');

export const shoprenterExpoCampaign: CampaignInfo = {
  title: 'Ecommerce Expo 2024 akció',
  subtitle: TwentyPercentDiscountSubtitle,
  end: shoprenterExpoCampaignEndDate,
  isActive: true,
  endAsTimeStamp: shoprenterExpoCampaignEndDate.valueOf(),
  content:
    'Azon Shoprenter bolt tulajdonosok, akik március 28-ig megrendelik valamely csomagunkat, az első  évre 20% kedvezményben részesülnek. ',
  bannerName: 'e-commerce_expo',
  bannerImageName: 'rs_expo_2024.png',
  pricingButtonTitle: 'Előfizetek',
  campaignNudge: `Az "Ecommerce expo 2024" `,
  pricingPageHeaderImage: 'rs_expo_2024_pricing_page.png',
};

const shoprenterSpringCampaignEndDate = moment('2024-05-15').endOf('day');
const allowedStarterPaidPlans = [RapidSearchPlans.STARTER, RapidSearchPlans.STARTER2, RapidSearchPlans.STARTER3];
const excludedStarterDiscountedPlans = [ShoprenterPlanFamily.STARTER];

export const shoprenterSpringCampaignTrial: CampaignInfo = {
  title: '2024 tavaszi akció',
  subtitle: TwentyPercentDiscountSubtitle,
  end: shoprenterSpringCampaignEndDate,
  isActive: true,
  endAsTimeStamp: shoprenterSpringCampaignEndDate.valueOf(),
  content:
    'Azon Shoprenter bolt tulajdonosok, akik május 15-ig megrendelik valamely csomagunkat, az első  évre 20% kedvezményben részesülnek.',
  bannerName: 'spring_campaign',
  bannerImageName: 'rs_spring_2024.png',
  pricingButtonTitle: 'Előfizetek',
  campaignNudge: `Az "2024 tavaszi Akció"`,
  pricingPageHeaderImage: 'rs_spring_2024_pricing_page.png',
  allowedPaidPlans: allowedStarterPaidPlans,
  excludedDiscountedPlans: excludedStarterDiscountedPlans,
};

export const shoprenterSpringCampaignStarter: CampaignInfo = {
  ...shoprenterSpringCampaignTrial,
  subtitle: '<p>Válts Advanced<br />csomagra 20% kedvezménnyel</p>',
  content:
    'Május 15-ig lehetőséged van <strong>20% kedvezménnyel felváltani</strong> Advanced csomagunkra <strong>az első egy évre</strong>. Az Advanced csomagban új bevételnövelő funkciókhoz férhetsz hozzá, ezáltal minimális befektetéssel jelentős bevételnövekedést érhetsz el!',
  bannerImageName: 'rs_spring_starter_2024.png',
  pricingPageHeaderImage: 'rs_spring_starter_2024_pricing_page.png',
};

export function getShoprenterBirthdayCampaign(campaignEndDate: Date, isStarterUser: boolean): CampaignInfo {
  const campaignEndMoment = moment(campaignEndDate);
  const notPaidSubtitle = TwentyPercentDiscountSubtitle;
  const starterSubtitle = 'Válts Advanced csomagra 20% kedvezménnyel';
  const notPaidContent =
    'Azon Shoprenter bolt tulajdonosok, akik megrendelik valamelyik akciós csomagunkat, az első  évre 20% kedvezményben részesülnek.';
  const starterPaidContent =
    'Most lehetőséged van <strong>20% kedvezménnyel felváltani</strong> Advanced csomagunkra <strong>az első egy évre</strong>. Az Advanced csomagban új bevételnövelő funkciókhoz férhetsz hozzá, ezáltal minimális befektetéssel jelentős bevételnövekedést érhetsz el.';

  return {
    title: '2024 születésnapi akció',
    subtitle: isStarterUser ? starterSubtitle : notPaidSubtitle,
    content: isStarterUser ? starterPaidContent : notPaidContent,
    isActive: true,
    end: campaignEndMoment,
    endAsTimeStamp: campaignEndMoment.valueOf(),
    bannerName: 'birthday_campaign',
    bannerImageName: `rs_birthday_2024${isStarterUser ? '_starter-1' : ''}.png`,
    pricingButtonTitle: 'Előfizetek',
    campaignNudge: 'A Rapid Search születésnapi akció 2024',
    pricingPageHeaderImage: `rs_birthday_starter_2024_pricing_page${isStarterUser ? '_starter2' : ''}.png`,
    allowedPaidPlans: allowedStarterPaidPlans,
    excludedDiscountedPlans: excludedStarterDiscountedPlans,
  };
}
